<template>
  <button
    :class="[
      'theme-button',
      {
        inactive: isInactive,
        active: isActive,
      },
    ]"
    :style="{ '--theme-color': `var(--${theme})` }"
    @click="$emit('onThemeSelect', theme)"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    {{ themes[theme] }}

    <DogEar
      class="theme-button__dog-ear"
      gradientColor="var(--basic-black)"
      :showArrow="true"
      :viz="true"
      :hideShadow="true"
      symbol="close"
      :whitePatchBlur="false"
    />
  </button>
</template>

<script>
import DogEar from "@/components/DogEar.vue";

export default {
  props: {
    theme: String,
    isActive: Boolean,
    isInactive: Boolean,
    // When the button is disabled, it basically functions as a tag
    disabled: Boolean,
  },
  data() {
    return {
      hoveredTheme: "",
    };
  },
  computed: {
    themes() {
      return this.$t("themes");
    },
  },

  components: {
    DogEar,
  },
};
</script>

<style lang="scss" scoped>
.theme-button {
  --dog-ear-size: 2.18rem;

  @include title-h-1;

  background-color: var(--theme-color);
  border: toRem(5) solid var(--theme-color);
  padding: toRem(10);
  border-radius: toRem(10);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // box-shadow: 0px 0px 8px 0px rgba(99, 99, 99, 0.2);
  position: relative;
  transition: opacity 0.3s;

  &.inactive {
    opacity: 0.6;
  }
  &__dog-ear {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
  }

  .desktop & {
    font-family: Spectral;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.16px;
    margin-right: toRem(24);
    border: none;
    height: 2.18rem;

    &:hover,
    &.active {
      box-shadow: 0px 4px 2px 0px rgba(130, 130, 130, 0.1),
        0px 0px 12px 0px rgba(99, 99, 99, 0.2);
    }

    &:hover {
      opacity: 1;

      &.active {
        .theme-button__dog-ear {
          opacity: 1;
        }
      }
    }
  }
}
</style>
