<template>
  <div class="title">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include title-h-4;

  text-transform: uppercase;
}

.desktop {
  .title {
    @include title-h-5;
    font-size: 0.75rem;
  }
}
</style>
