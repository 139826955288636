export default {
  methods: {
    /**
     * Converts number to locale and rounds it to 1 decimal
     *
     * @param {number} number
     * @returns string
     */
    localeAndRound(number) {
      return number.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 1,
      });
    },
  },
};
