/**
 * Strips the two end zeroes off an article nr
 *
 * @param {string} articleNr
 * @returns string
 */
export function formatArticleNumber(articleNr) {
  // Strip redundant zeroes
  return articleNr.replace(/00(\d)/, "$1").replace(/0(\d\d)/, "$1");
}
